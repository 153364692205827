import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import useToken from '../../hooks/useToken';
import React, { useEffect, useState } from 'react';
import { fetchWithTokenFromAPI2 } from '../../fetcher';
import Error from '../../components/Error';
import SettingForm from './SettingForm';
import config from '../../config';

import 'react-toastify/dist/ReactToastify.css';

export default function AddEditStation(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  let history = useHistory();

  let { id } = useParams();

  if (isTokenExpired()) {
    deleteToken();
  }

  // Load Setting details
  useEffect(() => {
    if (id !== undefined) {
      fetchWithTokenFromAPI2(
        `/settings/${id}`,
        {
          method: 'GET',
        },
        true,
      ).then(response => {
        setData(response);

        setLoading(false);
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  function handleUpdateSetting(value) {
    fetchWithTokenFromAPI2(
      `/settings/${value.key}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          value: value.value,
        })
      },
      true,
    ).then(response => {
      setTimeout(() => {
        history.push('/settings');
      }, config.saveDialogTimeout);
    });
  }

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  if (loading) return <h3>Loading...</h3>;

  return (
    <div>
      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />

      {!loading && (
        <SettingForm
          handleSubmit={handleUpdateSetting}
          initialValues={data}
        />
      )}
    </div>
  );
}
