import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import useToken from '../../hooks/useToken';
import { EditIcon } from '../../components/Icons';
import config from '../../config';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { fetchWithToken } from '../../fetcher';

export default function PerDiem(props) {
  let [submitting, setSubmitting] = useState(false);
  let { hasPermission } = useToken();
  let [payrollCycle, setPayrollCycle] = useState({});
  let [total, setTotal] = useState(0);
  let [totalFormatted, setTotalFormatted] = useState('$0');

  let perDiemRate = props.perDiemRate.value;

  let validationSchema = yup.object({
    daysWorked: yup.number()
      .required('Days Worked is required')
      .typeError('Days Worked must be a number')
      .max(7, 'Days Worked must be less than or equal to 7')
      .min(1, 'Days Worked must be greater than or equal to 1'),
  });

  let initialValues = {
    employeeName: '',
    employeeNumber: '',
    perDiemRate: '',
    perDiemRateFormatted: '',
    businessPurpose: '',
    daysWorked: '',
    payrollCycle: '',
  };

  if (props.initialValues) {
    if (Object.keys(props.initialValues).length > 0) {
      if (props.initialValues.employeeName) {
        initialValues.employeeName = props.initialValues.employeeName;
      }

      if (props.initialValues.employeeNumber) {
        initialValues.employeeNumber = props.initialValues.employeeNumber;
      }

      if (props.initialValues.businessPurpose) {
        initialValues.businessPurpose = props.initialValues.businessPurpose;
      }

      if (props.initialValues.daysWorked) {
        initialValues.daysWorked = props.initialValues.daysWorked;
      }

      if (props.initialValues.perDiemRate) {
        perDiemRate = props.initialValues.perDiemRate;
      }
    }
  }

  initialValues.perDiemRate = perDiemRate;
  initialValues.perDiemRateFormatted = `$${perDiemRate}`;

  useEffect(() => {
    if (!props.isAdding) {
      setTotal(initialValues.daysWorked * perDiemRate);
      setTotalFormatted(`$${initialValues.daysWorked * perDiemRate}`);
    }

    if(props.initialValues.payrollCycle) {
      setPayrollCycle(props.initialValues.payrollCycle);
      initialValues.payrollCycle = props.initialValues.payrollCycle;
    } else {
      initialValues.payrollCycle = props.payrollCycles.filter(x => x.current)[0].id;
      setPayrollCycle(initialValues.payrollCycle);
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);

      let data = {
        driverId: props.userId,
        businessPurpose: values.businessPurpose,
        payrollCycleId: values.payrollCycle,
        daysWorked: values.daysWorked,
      };

      props.handleSubmit(data);
    },
  });

  const handleDelete = (id) => {
    setSubmitting(true);
    props.handleDelete(id);
  };

  let payrollCycles = props.payrollCycles
    .map((x) => {
      let weekStart = DateTime.fromISO(x.weekStart, {
        zone: 'utc',
      }).toFormat('MM/dd/yyyy');

      let weekEnd = DateTime.fromISO(x.weekEnd, {
        zone: 'utc',
      }).toFormat('MM/dd/yyyy');

      return (
        <option key={x.id} value={x.id}>
          {weekStart} - {weekEnd}
        </option>
      );
    });

  const handlePayrollChange = (e) => {
    setPayrollCycle(e.target.value);

    formik.handleChange(e);
  };

  const handleDaysWorkedChange = (e) => {
    setTotal(e.target.value * perDiemRate);
    setTotalFormatted(`$${e.target.value * perDiemRate}`);

    formik.handleChange(e);
  };

  const { getFieldProps } = formik;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* Employee Name */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Employee Name</label>

            <input
              {...getFieldProps('employeeName')}
              className={`form-control ${
                formik.errors.employeeName && formik.touched.employeeName ? 'is-invalid' : ''
              }`}
              type="text"
              placeholder="Enter Employee Name"
              disabled={true}
              autoFocus
            />
          </div>

          {/* Employee Number */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Employee Number</label>

            <input
              {...getFieldProps('employeeNumber')}
              className={`form-control ${
                formik.errors.employeeNumber && formik.touched.employeeNumber ? 'is-invalid' : ''
              }`}
              type="text"
              placeholder="Enter Employee Number"
              disabled={true}
              autoFocus
            />
          </div>
        </div>

        <div className="row">
          {/* Business Purpose */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Business Purpose</label>

            <input
              {...getFieldProps('businessPurpose')}
              className={`form-control ${
                formik.errors.businessPurpose && formik.touched.businessPurpose ? 'is-invalid' : ''
              }`}
              type="text"
              placeholder="Enter Business Purpose"
              disabled={true}
              autoFocus
            />
          </div>

          {/* Payroll Cycle */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Payroll Cycle</label>

            <select
              {...getFieldProps('payrollCycle')}
              onChange={handlePayrollChange}
              className={`form-select ${
                formik.errors.payrollCycles && formik.touched.payrollCycles
                  ? 'is-invalid'
                  : ''
              }`}
              type="text"
            >
              <option value="">Choose a Payroll Cycle</option>

              {/* Pull in all other drivers */}
              {payrollCycles}
            </select>

            {formik.errors.payrollCycles && formik.touched.payrollCycles ? (
              <span style={{ color: '#D53939' }}>{formik.errors.payrollCycles}</span>
            ) : null}
          </div>
        </div>

        <div className="row">
          {/* Days Worked */}
          <div className="form-group col-md-4 mb-3">
            <label className="form-label">Days Worked</label>

            <input
              {...getFieldProps('daysWorked')}
              onChange={handleDaysWorkedChange}
              className={`form-control ${
                formik.errors.daysWorked && formik.touched.daysWorked
                  ? 'is-invalid'
                  : ''
              }`}
              type="text"
              placeholder="Enter Days Worked"
            />

            {formik.errors.daysWorked && formik.touched.daysWorked ? (
              <span style={{ color: '#D53939' }}>
                {formik.errors.daysWorked}
              </span>
            ) : null}
          </div>

          {/* Per Diem Rate */}
          <div className="form-group col-md-4 mb-3">
            <label className="form-label">Per Diem Rate</label>

            <input
              {...getFieldProps('perDiemRateFormatted')}
              className={`form-control`}
              type="text"
              placeholder="Enter Per Diem Rate"
              disabled={true}
              autoFocus
            />
          </div>

          {/* Total */}
          <div className="form-group col-md-4 mb-3">
            <label className="form-label">Total Per Diem (Days x Rate)</label>

            <input
              className={`form-control`}
              type="text"
              placeholder="Enter Total"
              disabled={true}
              autoFocus
              value={totalFormatted}
            />
          </div>
        </div>
        {/* Submit Button */}
        <button
          className="btn btn-primary"
          style={{ marginTop: '1em' }}
          type="submit"
        >
          Submit
        </button>

        {/* Delete Button */}
        {!props.isAdding && (
          <button
            className="btn btn-danger"
            style={{ marginTop: '1em', marginLeft: '1em' }}
            onClick={() => handleDelete(props.perDiemId)}
            type="button"
          >
            Delete
          </button>
        )}
      </form>
    </>
  );
}
