import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { fetchWithTokenFromAPI2 } from '../../fetcher';

import 'react-toastify/dist/ReactToastify.css';
import { getFakeFuelLocationDates, getFakeFuelLocations } from '../../fakeData';
import { DateTime } from 'luxon';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { EditIcon } from '../../components/Icons';
import useToken from '../../hooks/useToken';
import Error from '../../components/Error';

export default function Settings() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    fetchWithTokenFromAPI2(
      '/settings',
      {
        method: 'GET',
      },
      true,
    ).then(response => {
      setData(response);

      setLoading(false);
    });
  }, []);

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  if (loading) return <h3>Loading...</h3>;

  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Value',
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Link to={`/settings/${row.id}`} className="btn btn-link">
          <EditIcon /> Edit
        </Link>
      ),
    },
  ]

  return (
    <>
      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />

      <div className="card mt-2 mb-1">
        <DataTable data={data} columns={columns} pagination striped />
      </div>
    </>
  );
}
