import config from './config';

import './App.css';
import '@tabler/core/dist/js/tabler';

import { ApolloProvider } from '@apollo/client';
import Login from './pages/Login';
import Navigation from './components/Navigation';
import React from 'react';
import Router from './Router';
import useToken from './hooks/useToken';
import apolloClient from './apolloClient';
import Alert from './components/Alert';

const client = apolloClient;

export default function App() {
  const { token, setToken, deleteToken, hasPermission } = useToken();

  if (!token) {
    return <Login setToken={setToken} />;
  }

  // Toggle navbar options
  let showManagement = false;
  let showPurchaseOrders = false;
  let showReports = false;
  let showPerDiem = false;

  if (hasPermission('isadmin')) {
    showManagement = true;
    showReports = true;
  }

  if(hasPermission('createpo')) {
    showPurchaseOrders = true;
  }

  if(hasPermission('approvepo')) {
    showPurchaseOrders = true;
  }

  if(hasPermission('perdiem')) {
    showPerDiem = true;
  }

  return (
    <ApolloProvider client={client}>
      {config.showGlobalAlert && <Alert title={config.globalAlertTitle} description={config.globalAlertDescription} />}

      <Navigation
        deleteToken={deleteToken}
        showManagement={showManagement}
        showPurchaseOrders={showPurchaseOrders}
        showPerDiem={showPerDiem}
        showReports={showReports}
      ></Navigation>

      <div style={{ marginTop: '1em' }}>
        <Router />
      </div>
    </ApolloProvider>
  );
}
