import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import useToken from '../../hooks/useToken';
import { EditIcon, SearchIcon } from '../../components/Icons';
import config from '../../config';
import { fetchWithTokenFromAPI2 } from '../../fetcher';

export default function PerDiem() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { isTokenExpired, deleteToken, getUserId, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    fetchWithTokenFromAPI2(
      `/perdiem`,
      {
        method: 'GET',
      },
      true,
    ).then(response => {
      setData(response.map((x) => {
        let userId = getUserId();

        // Append edit visibility
        let isEditVisible = false;

        if (hasPermission('isadmin')) {
          isEditVisible = true;
        }

        if (x.payrollCycle.current) {
          isEditVisible = true;
        }

        return {
          isEditVisible,
          ...x,
        };
      }));

      setLoading(false);
    });
  }, []);

  if (loading) return <h3>Loading...</h3>;

  function toCurrency(numberString) {
    let number = parseFloat(numberString);

    let response = 'Not available';

    if (!isNaN(number)) {
      response = '$' + number.toLocaleString('USD');
    }

    return response;
  }

  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Driver',
      selector: (row) => `${row.driver.firstName} ${row.driver.lastName}`,
      sortable: true,
      width: '150px',
    },
    {
      name: 'Expense From',
      selector: (row) => row.payrollCycle.weekStartFormatted,
      sortable: true,
      width: '150px',
    },
    {
      name: 'Expense To',
      selector: (row) => row.payrollCycle.weekEndFormatted,
      sortable: true,
      width: '150px',
    },
    {
      name: 'Days Worked',
      selector: (row) => row.daysWorked,
      sortable: true,
    },
    {
      name: 'Rate',
      selector: (row) => row.perDiemRate,
      sortable: true,
      format: (row) => toCurrency(row.perDiemRate),
    },
    {
      name: 'Total Per Diem',
      selector: (row) => row.totalPerDiem,
      sortable: true,
      format: (row) => toCurrency(row.totalPerDiem),
    },
    {
      name: 'Submitted',
      selector: (row) => row.createdDate,
      sortable: true,
      format: (row, index) => {
        const estDate = DateTime.fromISO(row.createdDate, { zone: 'utc' }).setZone('America/New_York').toFormat('MM/dd/yyyy HH:mm:ss');

        return estDate;
      },
      width: '150px',
    },
    {
      name: 'Actions',
      cell: (row) => {
        return (
          <>
            {row.isEditVisible && (
              <Link to={`/perdiem/${row.id}`} className="btn btn-link">
                <EditIcon /> Edit
              </Link>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="card mt-2 mb-1">
        <DataTable data={data} columns={columns} pagination striped />
      </div>
    </>
  );
}
