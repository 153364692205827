import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery } from '@apollo/client';
import { Link, useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import useToken from '../../hooks/useToken';
import { EditIcon } from '../../components/Icons';
import config from '../../config';
import PurchaseOrderForm from '../purchaseorders/PurhaseOrderForm';
import PerDiemForm from '../perdiem/PerDiemForm';
import { fetchWithTokenFromAPI2 } from '../../fetcher';
import Error from '../../components/Error';

export default function AddEditPerDiem(props) {
  const { isTokenExpired, deleteToken, getUserId, hasPermission } = useToken();
  const [loading, setLoading] = useState(true);
  const [existing, setExisting] = useState({});
  const [existingLoading, setExistingLoading] = useState(true);
  const [user, setUser] = useState({});
  const [businessPurpose, setBusinessPurpose] = useState({});
  const [perDiemRate, setPerDiemRate] = useState({});
  const [payrollCycles, setPayrollCycles] = useState([]);

  let { id } = useParams();

  // Load Purchase Order details if we're editing
  useEffect(() => {
    if (id !== undefined) {
      fetchWithTokenFromAPI2(`/perdiem/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }, true).then(response => {
        setExisting(response);

        setExistingLoading(false);
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(async () => {
    const fetchData = async () => {
      let fetchedUser = await fetchWithTokenFromAPI2('/users/me', { method: 'GET' }, true);
      setUser(fetchedUser);

      let fetchedPerDiemRate = await fetchWithTokenFromAPI2('/settings/perdiemrate', { method: 'GET' }, true);
      setPerDiemRate(fetchedPerDiemRate);

      let fetchedBusinessPurpose = await fetchWithTokenFromAPI2('/settings/perdiembusinesspurpose', { method: 'GET' }, true);
      setBusinessPurpose(fetchedBusinessPurpose);

      let fetchedPayrollCycles = await fetchWithTokenFromAPI2('/payrollcycles', { method: 'GET' }, true);

      if (!hasPermission('isadmin')) {
        fetchedPayrollCycles = fetchedPayrollCycles.filter(x => !x.locked);
      }

      setPayrollCycles(fetchedPayrollCycles);

      setLoading(false);
    };

    fetchData().catch(error => {
      console.error(error);
    });
  }, []);

  let history = useHistory();

  async function handleSubmitPerDiem(values) {
    await fetchWithTokenFromAPI2('/perdiem', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }, true);

    try {
      setTimeout(() => {
        history.push('/perdiem');
      }, config.saveDialogTimeout);
    } catch (err) {
      let description = 'There was a problem submitting this Per Diem form';

      return <Error description={description} />;
    }
  }

  async function handleUpdatePerDiem(values) {
    await fetchWithTokenFromAPI2(`/perdiem/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }, true);

    try {
      setTimeout(() => {
        history.push('/perdiem');
      }, config.saveDialogTimeout);
    } catch (err) {
      let description = 'There was a problem updating this Per Diem form';

      return <Error description={description} />;
    }
  }

  async function handleDeletePerDiem(id) {
    await fetchWithTokenFromAPI2(`/perdiem/${id}`, {
      method: 'DELETE',
    }, false);

    try {
      setTimeout(() => {
        history.push('/perdiem');
      }, config.saveDialogTimeout);
    } catch (err) {
      let description = 'There was a problem deleting this Per Diem form';

      return <Error description={description} />;
    }
  }

  let initialValues = {
    employeeName: `${user.firstName} ${user.lastName}`,
    employeeNumber: user.eeNumber,
    businessPurpose: businessPurpose.value,
  };

  if (!existingLoading) {
    initialValues.employeeNumber = existing.driver.eeNumber;
    initialValues.employeeName = `${existing.driver.firstName} ${existing.driver.lastName}`;
    initialValues.businessPurpose = existing.businessPurpose;
    initialValues.daysWorked = existing.daysWorked;
    initialValues.perDiemRate = existing.perDiemRate;
    initialValues.payrollCycle = existing.payrollCycle.id;
  }

  let isReadyToAdd = props.isAdding;

  let isReadyToEdit = !props.isAdding && !existingLoading;

  if (loading) return <h3>Loading...</h3>;

  return (
    <>
      {isReadyToAdd && (
        <PerDiemForm
          isAdding={props.isAdding}
          initialValues={initialValues}
          payrollCycles={payrollCycles}
          perDiemRate={perDiemRate}
          userId={user.id}
          handleSubmit={handleSubmitPerDiem}
        />
      )}

      {isReadyToEdit && (
        <PerDiemForm
          perDiemId={id}
          isAdding={props.isAdding}
          initialValues={initialValues}
          payrollCycles={payrollCycles}
          perDiemRate={perDiemRate}
          userId={user.id}
          handleSubmit={handleUpdatePerDiem}
          handleDelete={handleDeletePerDiem}
        />
      )}
    </>
  );
}
