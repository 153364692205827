import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Message from '../../components/Message';

export default function SettingForm(props) {
  let [submitting, setSubmitting] = useState(false);
  let [validationSchema, setValidationSchema] = useState(null);

  let initialValues = {
    id: '',
    key: '',
    name: '',
    value: '',
  };

  if (props.initialValues) {
    if (Object.keys(props.initialValues).length > 0) {
      initialValues.id = props.initialValues.id;
      initialValues.key = props.initialValues.key;
      initialValues.name = props.initialValues.name;
      initialValues.value = props.initialValues.value;
    }
  }

  useEffect(() => {
    // Define the list of fields and their validation types
    const fields = [
      { name: 'value', type: props.initialValues.expectedDataType, required: true },
    ];

    // Create the dynamic validation schema
    const schema = fields.reduce((acc, field) => {
      let validator = yup[field.type]();
      if (field.required) {
        validator = validator.required(`${field.name} is required`);
      }
      acc[field.name] = validator;
      return acc;
    }, {});

    setValidationSchema(yup.object().shape(schema));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      props.handleSubmit(values);
    },
  });

  const { getFieldProps } = formik;

  if (submitting) {
    let title = 'Updating';
    let description = 'Please wait while the Setting is being updated';

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        {/* Name */}
        <div className="form-group col-md-12 mb-3">
          <label className="form-label">Name</label>

          <input
            {...getFieldProps('name')}
            className={`form-control ${
              formik.errors.name && formik.touched.name ? 'is-invalid' : ''
            }`}
            type="text"
            placeholder="Setting"
            disabled={true}
            autoFocus
          />

          {formik.errors.name && formik.touched.name ? (
            <span style={{ color: '#D53939' }}>{formik.errors.name}</span>
          ) : null}
        </div>

        {/* Value */}
        <div className="form-group col-md-12 mb-3">
          <label className="form-label">Value</label>
          <p>
            Please enter a value below.
          </p>

          <input
            {...getFieldProps('value')}
            className={`form-control ${
              formik.errors.value &&
              formik.touched.value
                ? 'is-invalid'
                : ''
            }`}
            type="text"
            placeholder="Enter Value"
          />

          {formik.errors.value &&
          formik.touched.value ? (
            <span style={{ color: '#D53939' }}>
              {formik.errors.value}
            </span>
          ) : null}
        </div>
      </div>

      {/* Submit Button */}
      <button
        className="btn btn-primary"
        style={{ marginTop: '1em' }}
        type="submit"
      >
        Submit
      </button>
    </form>
  );
}
